.ag-cell-value, .ag-group-value {
    overflow: visible !important;
}
.react-datepicker-wrapper {
  width: auto !important;
}
a {
  text-decoration: none;
}
.iubenda-nostyle {
  color: inherit !important;
}