@layer mantine, mantine-datatable;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mantine-Accordion-root.maiba-custom {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mantine-Accordion-root.maiba-custom > * {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}
.mantine-Accordion-root.maiba-custom > *[data-active="true"] {
  flex-grow: 1;
}
.mantine-Accordion-root.maiba-custom .mantine-Accordion-panel {
  position: relative;
  flex-grow: 1;
}
.mantine-Accordion-root.maiba-custom .mantine-ScrollArea-root {
  position: absolute;
  top: calc(30px + var(--mantine-spacing-xs));
  right: 0;
  left: 0;
  bottom: 0;
}
.mantine-Accordion-root.maiba-custom .mantine-ScrollArea-viewport > div {
  display: block !important;
  max-width: 100%;
}
.mantine-Accordion-root.maiba-custom .mantine-Accordion-item:first-child .mantine-ScrollArea-viewport .mantine-Group-root .mantine-Button-root {
  width: calc(100% - 28px);
  flex-basis: calc(100% - 28px);
}

.mantine-RingProgress-curve {
  stroke-linecap: round;
}

.mantine-Pill-label .ricambio {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.mantine-Pill-label .ricambio p {
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}
.mantine-Pill-label .ricambio p:last-child {
  display: none;
}

.carousel-modal .mantine-Modal-overlay {
  background: rgba(0,0,0,.8);
}
.carousel-modal .mantine-Modal-content {
  background-color: transparent !important;
}
.carousel-modal .mantine-Modal-header, .carousel-modal .mantine-Modal-body {
  background-color: inherit !important;
}
.carousel-modal .mantine-Modal-body {
  padding: 0 0 var(--mb-padding,var(--mantine-spacing-md)) 0;
}
.carousel-modal .mantine-Modal-header h2 {
  color: #fff;
}
.carousel-modal .mantine-Modal-header button {
  --_cb-color: #fff !important;
  --_cb-bg: transparent !important;
}